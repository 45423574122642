import { Box } from "@mui/material";
import { RelatedPartyFormWrapper } from "Components/Users/UserDetailPageContent/Components/RelatedPartyFormWrapper";
import { RelatedPartyDto } from "Infrastructure/Api/Api";

type RelatedPartiesProps = {
  relatedParties: Array<RelatedPartyDto>;
};

export const RelatedParties = ({ relatedParties }: RelatedPartiesProps) => (
  <Box display="flex" flexDirection="column" gap="1rem">
    {relatedParties.map(relatedParty => (
      <RelatedPartyFormWrapper
        key={relatedParty.publicID}
        relatedParty={relatedParty}
      />
    ))}
  </Box>
);
