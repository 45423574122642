import { PartyType, RelatedPartyDto } from "Infrastructure/Api/Api";
import { zodResolver } from "@hookform/resolvers/zod";
import { RelationType } from "Infrastructure/Api/Api";
import { useForm } from "react-hook-form";
import { createPartyValidationSchema } from "Components/Users/schemas";
import { FormEvent } from "react";
import { createBeneficialOwnerValidationSchema } from "Components/Users/schemas";
import { PartyDto } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { toast } from "sonner";
import {
  StyledForm,
  StyledSubmitButton,
} from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { RelatedPartyFormFields } from "Components/Users/UserDetailPageContent/Components/RelatedPartyFormFields";
import { BeneficialOwnerPartyFormFields } from "Components/Users/UserDetailPageContent/Components/BeneficialOwnerPartyFormFields";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

type RelatedPartyFormProps = {
  relatedParty: RelatedPartyDto;
  partyData: PartyDto;
};

const RELATION_RESOURCES = Resources.Form.RelationType;

export const RelatedPartyForm = ({
  relatedParty,
  partyData,
}: RelatedPartyFormProps) => {
  const { t } = useResource();
  const isRelatedPartyOwner =
    relatedParty.relationType === RelationType.BeneficialOwner;
  const schema = isRelatedPartyOwner
    ? createBeneficialOwnerValidationSchema(t)
    : createPartyValidationSchema(partyData.type || PartyType.NaturalPerson);

  const {
    control,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<PartyDto>({
    resolver: zodResolver(schema),
    defaultValues: partyData,
  });

  const { mutate: updateParty, isPending } = usePartyMutation({
    onSuccess: ({ data }) => {
      reset(data);
      toast.success("Uložení údajů související osoby proběhlo úspěšně");
    },
    onError: () =>
      toast.error("Při ukládání údajů související osoby se vyskytla chyba"),
  });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(data => {
      updateParty({
        publicID: data.publicID!,
        data: data,
      });
    })();
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <PrimaryPaper>
        {isRelatedPartyOwner ? (
          <BeneficialOwnerPartyFormFields
            control={control}
            addresses={partyData.addresses}
          />
        ) : (
          <RelatedPartyFormFields
            control={control}
            relationType={relatedParty.relationType}
            addresses={partyData.addresses}
          />
        )}
        <StyledSubmitButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit | {t(RELATION_RESOURCES[relatedParty.relationType])}
        </StyledSubmitButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
