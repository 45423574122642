import { FormEvent } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type UserAdminDetailDto } from "Infrastructure/Api/Api";

import { UserFormFields } from "Components/Users/UserDetailPageContent/Components/UserFormFields";
import { useUpdateUserMutation } from "Api/Mutations/User/useUpdateUserMutation";
import { toast } from "sonner";
import {
  StyledForm,
  StyledSubmitButton,
} from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { userValidationSchema } from "Components/Users/schemas";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

type UserFormProps = {
  user: UserAdminDetailDto;
};

export const UserForm = ({ user }: UserFormProps) => {
  const {
    handleSubmit: handleUserSubmit,
    control: userControl,
    formState: { isDirty },
    reset,
  } = useForm<UserAdminDetailDto>({
    resolver: zodResolver(userValidationSchema),
    defaultValues: user,
  });

  const { mutate: updateUser, isPending } = useUpdateUserMutation({
    onSuccess: data => {
      reset(data);
      toast.success("Uložení údajů uživatele proběhlo úspěšně");
    },
    onError: () =>
      toast.error("Při ukládání údajů uživatele se vyskytla chyba"),
  });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleUserSubmit(data => {
      updateUser(data);
    })();
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <PrimaryPaper>
        <UserFormFields control={userControl} />
        <StyledSubmitButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit
        </StyledSubmitButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
