import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import styled from "styled-components";

export const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing(2, 0, 0, 0)};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(3)};
`;
