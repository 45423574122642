import { RelatedPartyDto } from "Infrastructure/Api/Api";
import { RelatedPartyForm } from "Components/Users/UserDetailPageContent/Components/RelatedPartyForm";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { Skeleton } from "@mui/material";

type RelatedPartyFormWrapperProps = {
  relatedParty: RelatedPartyDto;
};

export const RelatedPartyFormWrapper = ({
  relatedParty,
}: RelatedPartyFormWrapperProps) => {
  const { data: relatedPartyData, isLoading } = usePartyQuery(
    relatedParty.relatedPartyPublicID,
  );

  if (isLoading) {
    return <Skeleton height={800} variant="rounded" sx={{ mt: "0.5rem" }} />;
  }

  if (!relatedPartyData) {
    return <>Chyba při načítání dat související osoby.</>;
  }

  return (
    <RelatedPartyForm
      relatedParty={relatedParty}
      partyData={relatedPartyData}
    />
  );
};
