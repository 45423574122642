import { Control } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { PartyDto, AddressDto } from "Infrastructure/Api/Api";
import { CountryDropdown } from "Components/Shared/Inputs/Form/Dropdowns/CountryDropdown";
import { usePartyTypeList } from "Hooks/Options/usePartyTypeList";
import { useGenderList } from "Hooks/Options/useGenderList";
import { IdentificationCardDropdown } from "Components/Shared/Inputs/Form/Dropdowns/IdentificationCardDropdown";
import { AddressFields } from "Components/Users/UserDetailPageContent/Components/AddressFields";
import { Box } from "@mui/material";
import { ReactNode } from "react";

type PartyFormFieldsProps = {
  control: Control<PartyDto>;
  addresses: Array<AddressDto>;
  additionalIdentificationsSlot?: ReactNode;
};

export const PartyFormFields = ({
  control,
  addresses,
  additionalIdentificationsSlot,
}: PartyFormFieldsProps) => {
  const { t } = useResource();
  const genderList = useGenderList();
  const partyTypeList = usePartyTypeList({ onlyBusiness: false });

  return (
    <>
      <Box component="fieldset" sx={{ all: "unset", display: "grid" }}>
        <Dropdown
          codeList={partyTypeList}
          name="type"
          control={control}
          label="Typ osoby"
        />
        <FormInput control={control} name="firstName" label="Jméno" />
        <FormInput control={control} name="lastName" label="Příjmení" />
        <FormInput
          control={control}
          name="companyName"
          label="Název společnosti"
        />
        <FormInput control={control} name="email" label="email" />
        <FormInput control={control} name="taxNumber" label="DIČ" />
        <FormInput control={control} name="companyNumber" label="IČO" />
        <FormInput
          control={control}
          name="personalNumber"
          label="Rodné číslo"
        />
        <FormInput
          type="date"
          control={control}
          name="birthDate"
          label="Datum narození"
        />
        <Dropdown
          control={control}
          name="gender"
          label="Pohlaví"
          codeList={genderList}
        />
        <FormInput control={control} name="birthPlace" label="Místo narození" />
        <CountryDropdown
          control={control}
          name="nationalityCountryID"
          label={t(Resources.Common.Nationality)}
        />
        <FormInput
          control={control}
          name="phone"
          label="Telefon"
          mask="+000 000 000 000"
          inputProps={{
            inputMode: "tel",
          }}
        />
        <FormInput control={control} name="email" label="E-mail" />
        <IdentificationCardDropdown
          control={control}
          name="identification"
          label="Typ identifikace"
        />
        <FormInput
          control={control}
          name="identificationNumber"
          label="Číslo dokladu"
        />
        <FormInput
          type="date"
          control={control}
          name="identificationValidFrom"
          label="Platnost od"
        />
        <FormInput
          type="date"
          control={control}
          name="identificationValidTo"
          label="Platnost do"
        />
        <FormInput
          control={control}
          name="identificationIssuer"
          label="Vydavatel"
        />
        <Box mb="2rem">{additionalIdentificationsSlot}</Box>
      </Box>
      <AddressFields addresses={addresses} control={control} />
    </>
  );
};
