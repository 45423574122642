import { AddressFields } from "Components/Users/UserDetailPageContent/Components/AddressFields";
import { AddressDto } from "Infrastructure/Api/Api";
import { RelationType } from "Infrastructure/Api/Api";
import { Control } from "react-hook-form";
import { PartyDto } from "Infrastructure/Api/Api";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { Box } from "@mui/material";
import { Resources, useResource } from "Translations/Resources";
import { CountryDropdown } from "Components/Shared/Inputs/Form/Dropdowns/CountryDropdown";
import { useGenderList } from "Hooks/Options/useGenderList";
import { IdentificationCardDropdown } from "Components/Shared/Inputs/Form/Dropdowns/IdentificationCardDropdown";
import { PaperTitle } from "Components/Shared/PaperTitle";

const RELATION_RESOURCES = Resources.Form.RelationType;

type RelatedPartyFormFieldsProps = {
  control: Control<PartyDto>;
  relationType: RelationType;
  addresses: Array<AddressDto>;
};

export const RelatedPartyFormFields = ({
  control,
  addresses,
  relationType,
}: RelatedPartyFormFieldsProps) => {
  const { t } = useResource();
  const genderList = useGenderList();

  return (
    <Box component="fieldset" sx={{ all: "unset", display: "grid" }}>
      <PaperTitle component="legend" sx={{ m: "0 0 3% 0" }}>
        {t(RELATION_RESOURCES[relationType])}
      </PaperTitle>
      <FormInput control={control} name="firstName" label="Jméno" />
      <FormInput control={control} name="lastName" label="Příjmení" />
      <FormInput
        control={control}
        name="companyName"
        label="Název společnosti"
      />
      <FormInput control={control} name="companyNumber" label="IČO" />
      <FormInput control={control} name="personalNumber" label="Rodné číslo" />
      <FormInput
        control={control}
        name="birthDate"
        label="Datum narození"
        type="date"
      />
      <Dropdown
        control={control}
        name="gender"
        label="Pohlaví"
        codeList={genderList}
      />
      <FormInput control={control} name="birthPlace" label="Místo narození" />
      <FormInput control={control} name="phone" label="Telefon" />
      <CountryDropdown
        control={control}
        name="nationalityCountryID"
        label={t(Resources.Common.Nationality)}
      />
      <FormInput control={control} name="email" label="E-mail" />
      <IdentificationCardDropdown
        control={control}
        name="identification"
        label="Typ identifikace"
      />
      <FormInput
        control={control}
        name="identificationNumber"
        label="Číslo identifikace"
      />
      <FormInput
        control={control}
        name="identificationValidFrom"
        label="Platnost od"
        type="date"
      />
      <FormInput
        control={control}
        name="identificationValidTo"
        label="Platnost do"
        type="date"
      />
      <FormInput
        control={control}
        name="identificationIssuer"
        label="Vydavatel"
      />
      <AddressFields control={control} addresses={addresses} />
    </Box>
  );
};
