import { Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { useIsMobile } from "Hooks/useIsMobile";
import { PageTitle } from "Components/Shared/PageTitles/PageTitle";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";

const StyledWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.theme.spacing(6, 0, 0, 12)};
  z-index: 2;

  ${props => props.theme.breakpoints.down("lg")} {
    padding: ${props => props.theme.spacing(6, 3)};
  }
`;

const StyledChildrenWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(6)};
  height: 100%;
`;

type Props = {
  title: string;
  goBackUrl?: string;
  onChevronClick?: () => void;
};

export const OnboardingLayout: React.FC<
  React.PropsWithChildren<Props>
> = props => {
  const { title, onChevronClick } = props;

  const { t } = useResource();
  const { isMobile } = useIsMobile();

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <>
      <Box overflow="hidden" display="flex">
        <StyledWrapper marginBottom={isMobile ? 0 : 8}>
          <PageTitle title={title} onChevronClick={onChevronClick} />
          <StyledChildrenWrapper>
            <StyledPageWrapper>
              <Box height="100%" className="left-content">
                <PrimaryPaper sx={{ height: "100%" }}>
                  {props.children}
                </PrimaryPaper>
              </Box>
            </StyledPageWrapper>
          </StyledChildrenWrapper>
        </StyledWrapper>
      </Box>
    </>
  );
};
