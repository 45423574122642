import { useOCR } from "Api/Mutations/Onboarding/useOCR";
import { useCreatePartyRelationMutation } from "Api/Mutations/Party/useCreatePartyRelationMutation";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { useUpdateVerificationsMutation } from "Api/Mutations/Verifications/useUpdateVerificationsMutation";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { useRelatedParty } from "Components/Onboarding/BusinessEntity/Hooks/useRelatedParty";
import {
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { getMappedAresToForm } from "Components/Onboarding/BusinessEntity/Utils/aresUtils";
import { getFormDataFromOCR } from "Components/Onboarding/BusinessEntity/Utils/ocrUtils";
import {
  getBusinessPartyDataFromForm,
  getInitialFormDataFromParty,
  getPartyDataFromForm,
} from "Components/Onboarding/BusinessEntity/Utils/partyUtils";
import { getFormDataFromVerifications } from "Components/Onboarding/BusinessEntity/Utils/verificationUtils";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { PartyDto, PartyType, RelationType } from "Infrastructure/Api/Api";
import { logError } from "Infrastructure/Utils/LoggingUtils";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { DeepPartial } from "types";
import { appUrl } from "Utils/UrlUtils";

interface GetFormValuesInterface {
  <T extends OnboardingSteps>(step: T): Partial<FormModel[T]>;
}

type OnboardingContextType = {
  step: OnboardingSteps;
  nextStep: (values: unknown) => void;
  prevStep: () => void;
  setFormValues: (step: OnboardingSteps, values: unknown) => void;
  getFormValues: GetFormValuesInterface;
  setAresData: (aresData: PartyDto | null) => void;
  hasPrevStep: boolean;
  isLoadingNextStep: boolean;
  isLoadingOnboarding: boolean;
};

const OnboardingContext = createContext<OnboardingContextType>(
  {} as OnboardingContextType,
);

export const OnboardingContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const user = useLoggedInUser();

  const [step, setStep] = useState<OnboardingSteps>(
    OnboardingSteps.BusinessInformation,
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const [actingPersonParty, setActingPersonParty] = useState<{
    publicID?: string;
    relatedPublicID?: string;
  }>();

  const formValues = useRef<DeepPartial<FormModel>>({
    [OnboardingSteps.AdditionalInformation]: {
      isOwner: true,
    },
  });
  const actingPersionIsOwner = useRef(false);

  const navigate = useNavigate();

  const { data: partyData } = usePartyQuery(user?.partyPublicID);
  const { data: verificationData } = useVerificationsQuery();
  const { data: actingPersonPartyData, isLoading: isLoadingactingPersonParty } =
    usePartyQuery(actingPersonParty?.publicID);

  useEffect(() => {
    if (!initialLoading) {
      return;
    }

    // replace only if the form is empty
    if (partyData) {
      const { formData, actingPerson } = getInitialFormDataFromParty(
        partyData,
        formValues.current ?? {},
      );
      formValues.current = formData;

      setActingPersonParty({
        relatedPublicID: actingPerson.relatedPartyPublicID,
        publicID: actingPerson.partyPublicID,
      });

      if (actingPerson?.isOwner) {
        actingPersionIsOwner.current = true;
      }
    }

    if (verificationData) {
      formValues.current = getFormDataFromVerifications(
        verificationData,
        formValues.current ?? {},
      );
    }

    if (verificationData && partyData) {
      setInitialLoading(false);
    }
  }, [partyData, verificationData, initialLoading]);

  const { mutate: putParty, isPending: isPartyMutating } = usePartyMutation({
    onSuccess: () => {
      // When type is not legal entity, we need to skip additional information step
      const nextStep =
        step === OnboardingSteps.UserDetail &&
        formValues.current[OnboardingSteps.BusinessInformation]?.partyType !==
          PartyType.LegalEntity
          ? OnboardingSteps.BankAccountConnection
          : step + 1;

      setStep(nextStep);
    },
  });

  const { updateRelatedParty, isPending: isRelatedPartyUpdating } =
    useRelatedParty({
      legalEntityPartyID: user?.partyPublicID!,
      partyPublicID: actingPersonParty?.publicID,
      relatedPartyPublicID: actingPersonParty?.relatedPublicID,
      onSuccessUpdate: ({ relatedPartyPublicID, partyPublicID }) => {
        setActingPersonParty({
          publicID: partyPublicID,
          relatedPublicID: relatedPartyPublicID,
        });
        // we can skip documents upload if we have already identification number (the user document was already uploaded)
        if (actingPersonPartyData?.identificationNumber) {
          formValues.current = getFormDataFromOCR(
            actingPersonPartyData,
            formValues.current ?? {},
          );
          setStep(OnboardingSteps.UserDetail);
        } else {
          setStep(OnboardingSteps.PersonalDocument);
        }
      },
    });

  const { mutate: postPartyRelation, isPending: isPartyRelationCreating } =
    useCreatePartyRelationMutation({
      onSuccess: () => {
        actingPersionIsOwner.current = true;
        setStep(step + 1);
      },
    });

  const { mutate: putVerification, isPending: isVerificationMutating } =
    useUpdateVerificationsMutation({
      onSuccess: () => {
        if (
          formValues.current[OnboardingSteps.BusinessInformation]?.partyType !==
          PartyType.LegalEntity
        ) {
          setStep(step + 1);
          return;
        }

        updateRelatedParty({
          relationType:
            formValues.current[OnboardingSteps.IncomeInformation]
              ?.relationType!,
        });
      },
    });
  const { mutateAsync: ocrRequest, isPending: isProcessingOcr } = useOCR({
    onSuccess: result => {
      if (!result.data?.publicID) {
        toast.error("Chyba při zpracování dokumentů");
        logError(new Error("OCR failed - publicID missing"));

        return;
      }

      if (result.data) {
        formValues.current = getFormDataFromOCR(
          result.data,
          formValues.current ?? {},
        );

        setStep(step + 1);
      }
    },
  });

  const isLoadingNextStep =
    isPartyMutating ||
    isVerificationMutating ||
    isLoadingactingPersonParty ||
    isRelatedPartyUpdating ||
    isPartyRelationCreating ||
    isProcessingOcr;

  const nextStep = (values: unknown) => {
    if (isLoadingNextStep) {
      return;
    }

    setFormValues(step, values);

    if (step === OnboardingSteps.BusinessInformation) {
      putParty({
        publicID: user?.partyPublicID!,
        data: getBusinessPartyDataFromForm(formValues.current, partyData!),
      });
    } else if (step === OnboardingSteps.IncomeInformation) {
      const {
        businessActivity,
        otherBusinessActivityDetails,
        averageTransactionValue,
        expectedMonthlyRevenue,
        incomeSource,
        isPoliticallyExposed,
        taxResidence,
        incomeSourceDetail,
      } = formValues.current[OnboardingSteps.IncomeInformation] ?? {};
      putVerification({
        businessActivity,
        otherBusinessActivityDetails,
        averageTransactionValue,
        expectedMonthlyRevenue,
        taxResidencyCountryID: taxResidence,
        sourceOfIncome: incomeSource,
        otherSourceOfIncomeDetails: incomeSourceDetail,
        isPoliticallyExposedPerson: !!isPoliticallyExposed,
      });
    } else if (step === OnboardingSteps.ManualAccountVerification) {
      navigate(appUrl("dashboard"));
    } else if (step === OnboardingSteps.PersonalDocument) {
      const documents = formValues.current[OnboardingSteps.PersonalDocument];

      ocrRequest({
        documents: {
          firstIDCardFrontSide: documents!.firstDocument!.frontSide as File,
          firstIDCardBackSide: documents!.firstDocument!.backSide as File,
          secondIDCardFrontSide: documents!.secondDocument!.frontSide as File,
        },
        partyPublicID:
          actingPersonParty?.publicID || (user!.partyPublicID as string),
      });
    } else if (step === OnboardingSteps.UserDetail) {
      putParty({
        publicID: actingPersonParty?.publicID || user?.partyPublicID!,
        data: getPartyDataFromForm(
          formValues.current as FormModel,
          actingPersonPartyData ? actingPersonPartyData : partyData!,
        ),
      });
    } else if (step === OnboardingSteps.AdditionalInformation) {
      if (
        actingPersonParty?.publicID &&
        formValues.current[OnboardingSteps.AdditionalInformation]?.isOwner &&
        !actingPersionIsOwner.current
      ) {
        postPartyRelation({
          partyPublicID: user?.partyPublicID!,
          data: {
            relatedPartyPublicID: actingPersonParty.publicID,
            relationType: RelationType.BeneficialOwner,
          },
        });
      } else if (
        actingPersionIsOwner.current &&
        !formValues.current[OnboardingSteps.AdditionalInformation]?.isOwner
      ) {
        // TODO: remove relation for action person as owner
        setStep(step + 1);
      } else {
        setStep(step + 1);
      }
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    const nextStep =
      step === OnboardingSteps.BankAccountConnection &&
      formValues.current[OnboardingSteps.BusinessInformation]?.partyType !==
        PartyType.LegalEntity
        ? OnboardingSteps.UserDetail
        : step - 1;

    setStep(Math.max(0, nextStep));
  };

  const getFormValues: GetFormValuesInterface = step =>
    formValues.current[step] || {};
  const setFormValues = (step: OnboardingSteps, values: unknown = {}) => {
    formValues.current = {
      ...formValues.current,
      [step]: values,
    };
  };

  const setAresData = (aresData: PartyDto | null) => {
    if (!aresData) {
      return;
    }

    formValues.current = getMappedAresToForm(aresData, formValues.current);
  };
  return (
    <OnboardingContext.Provider
      value={{
        step,
        nextStep,
        prevStep,
        getFormValues,
        setFormValues,
        setAresData,
        hasPrevStep: step > OnboardingSteps.BusinessInformation,
        isLoadingNextStep,
        isLoadingOnboarding: initialLoading,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
