import { AddressType, UserDetailModel } from "Components/Onboarding/types";
import {
  AverageTransactionValue,
  BusinessActivity,
  ExpectedMonthlyRevenue,
  PartyType,
  RelationType,
  SourceOfIncome,
} from "Infrastructure/Api/Api";

export enum OnboardingSteps {
  BusinessInformation,
  IncomeInformation,
  PersonalDocument,
  UserDetail,
  AdditionalInformation,
  BankAccountConnection,
  ManualAccountVerification,
}

export type BusinessInformationModel = {
  partyType: PartyType;
  identificationNumber: string;
  companyName: string;
  address: AddressType;
  contact: {
    phone: string;
  };
};

export type IncomeInformationModel = {
  businessActivity: BusinessActivity;
  otherBusinessActivityDetails?: string;
  incomeSource: SourceOfIncome;
  incomeSourceDetail?: string;
  expectedMonthlyRevenue: ExpectedMonthlyRevenue;
  averageTransactionValue: AverageTransactionValue;
  taxResidence: number;
  relationType?: RelationType;
  isPoliticallyExposed: boolean;
};

export type PersonalDocumentModel = {
  firstDocument: {
    frontSide: File;
    backSide: File;
  };
  secondDocument: {
    frontSide: File;
  };
};

export type LastStepModel = {
  phone: string;
  politicallyExposed: boolean;
};

export type AdditionalInformationModel = {
  isOwner: boolean;
  owners?: {
    publicID: string;
  }[];
};

export type OwnerType = {
  firstName: string;
  lastName: string;
  birthDate: Date;
  nationality: number;
  address: AddressType;
};

export type FormModel = {
  [OnboardingSteps.BusinessInformation]: BusinessInformationModel;
  [OnboardingSteps.IncomeInformation]: IncomeInformationModel;
  [OnboardingSteps.PersonalDocument]: PersonalDocumentModel;
  [OnboardingSteps.UserDetail]: UserDetailModel;
  [OnboardingSteps.AdditionalInformation]: AdditionalInformationModel;
  [OnboardingSteps.BankAccountConnection]: unknown;
  [OnboardingSteps.ManualAccountVerification]: unknown;
};
