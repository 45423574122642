import { FormEvent, ReactNode } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PartyDto } from "Infrastructure/Api/Api";

import { PartyFormFields } from "Components/Users/UserDetailPageContent/Components/PartyFormFields";
import { toast } from "sonner";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { createPartyValidationSchema } from "Components/Users/schemas";
import {
  StyledForm,
  StyledSubmitButton,
} from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

type PartyFormProps = {
  party: PartyDto;
  additionalIdentificationsSlot?: ReactNode;
};

export const PartyForm = ({
  party,
  additionalIdentificationsSlot,
}: PartyFormProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<PartyDto>({
    resolver: zodResolver(createPartyValidationSchema(party.type)),
    defaultValues: party,
  });

  const { mutate: updateParty, isPending } = usePartyMutation({
    onSuccess: ({ data }) => {
      reset(data);
      toast.success("Uložení údajů party proběhlo úspěšně");
    },
    onError: () => toast.error("Při ukládání údajů party se vyskytla chyba"),
  });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(data => {
      updateParty({ publicID: data.publicID!, data });
    })();
  };

  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        <PrimaryPaper>
          <PartyFormFields
            control={control}
            addresses={party.addresses}
            additionalIdentificationsSlot={additionalIdentificationsSlot}
          />
          <StyledSubmitButton
            fullWidth
            type="submit"
            disabled={!isDirty}
            isLoading={isPending}
            variant="contained"
            color="primary"
          >
            Uložit
          </StyledSubmitButton>
        </PrimaryPaper>
      </StyledForm>
    </>
  );
};
